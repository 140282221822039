import cn from 'classnames';
import Link from 'next/link';
import { useState } from 'react';

import { COLORS } from '@Constants/enums';

import styles from './ListPicker.module.scss';
import { ListPickerProps, ListProps } from './ListPicker.props';

const List = ({ label, href, active, onClick }: ListProps) => {
  return (
    <Link key={label} href={href}>
      <a href={href} onClick={onClick}>
        <li
          className={cn(styles.ListPicker__List, `color-${active ? COLORS.ORANGE : COLORS.BLACK}`)}>
          {label}
        </li>
      </a>
    </Link>
  );
};

export const ListPicker = ({ title, items, className, setCategoryQuestions }: ListPickerProps) => {
  const [isActiveItem, setIsActiveItem] = useState<string>('Algemeen');

  const toggleActive = (item: string, setCategoryQuestions: any) => {
    setIsActiveItem(item);
    setCategoryQuestions(item);
  };
  return (
    <div className={cn(styles.ListPicker, className)}>
      <h3 className={styles.ListPicker__Title}>{title}</h3>
      <ul>
        {items.map((item: ListProps) => (
          <List
            label={item.label}
            href={item.href}
            active={item.label === isActiveItem}
            onClick={() => toggleActive(item.label, setCategoryQuestions)}
          />
        ))}
      </ul>
    </div>
  );
};
