import { captureException } from '@sentry/minimal';
import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form } from '@Components/ui';
import { COLORS } from '@Constants/enums';
import { mail } from '@Utils/MailUtils';
import Yup from '@Utils/YupUtils';

import styles from './ContactForm.module.scss';
import { ContactFormData, ContactFormProps } from './ContactForm.props';

export const ContactForm = ({ className }: ContactFormProps) => {
  const { t } = useTranslation();
  const [hasSent, setHasSent] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    message: Yup.string().required(),
  });

  const onSubmit = async (data: ContactFormData) => {
    try {
      const body = `
        <table>
          <tr>
            <td>
              <strong>Naam</strong>
              <div>${data.name}</div>
            </td>
          </tr>
  
          <tr>
            <td>
              <strong>E-mailadres</strong>
              <div><a href="mailto:${data.email}">${data.email}</a></div>
            </td>
          </tr>
  
          <tr>
            <td>
              <strong>Bedrijfsnaam</strong>
              <div>${data.company || '-'}</div>
            </td>
          </tr>
  
          <tr>
            <td>
              <strong>Telefoonnummer</strong>
              <div><a href="tel:${data.phone}">${data.phone}</a></div>
            </td>
          </tr>
  
          <tr>
            <td>
              <strong>Bericht</strong>
              <div>${data.message}</div>
            </td>
          </tr>
        </table>
      `;

      await mail(
        String(process.env.NEXT_PUBLIC_EMAIL_ADMIN),
        String(process.env.NEXT_PUBLIC_EMAIL_FROM),
        'Contentamersfoort - Contactaanvraag',
        {
          title: 'Nieuwe <strong>contactvraag</strong> ingediend',
          body: body,
        },
      );

      setHasSent(true);
    } catch (e) {
      captureException(e);
      alert(t('GLOBALS.ERRORS.GENERIC'));
    }
  };

  return (
    <div className={cn(styles.ContactForm, className)}>
      <h2>{t('FORMS.CONTACT_FORM.TITLE')}</h2>

      {hasSent && <div dangerouslySetInnerHTML={{ __html: t('FORMS.CONTACT_FORM.SUCCESS') }} />}

      {!hasSent && (
        <Form.Form validationSchema={validationSchema} onSubmit={onSubmit}>
          <Form.Row>
            <Form.Field label={t('FORMS.CONTACT_FORM.NAME_LABEL')} isRequired={true}>
              <Form.TextInput name="name" placeholder={t('GLOBALS.DEFAULT_PLACEHOLDER')} />
            </Form.Field>

            <Form.Field label={t('FORMS.CONTACT_FORM.EMAIL_LABEL')} isRequired={true}>
              <Form.TextInput name="email" placeholder={t('GLOBALS.DEFAULT_PLACEHOLDER')} />
            </Form.Field>
          </Form.Row>

          <Form.Row>
            <Form.Field label={t('FORMS.CONTACT_FORM.COMPANY_LABEL')}>
              <Form.TextInput name="company" placeholder={t('GLOBALS.DEFAULT_PLACEHOLDER')} />
            </Form.Field>

            <Form.Field label={t('FORMS.CONTACT_FORM.PHONE_LABEL')}>
              <Form.TextInput name="phone" placeholder={t('GLOBALS.DEFAULT_PLACEHOLDER')} />
            </Form.Field>
          </Form.Row>

          <Form.Field label={t('FORMS.CONTACT_FORM.MESSAGE_LABEL')} isRequired={true}>
            <Form.TextAreaInput name="message" placeholder={t('GLOBALS.DEFAULT_PLACEHOLDER')} />
          </Form.Field>

          <div className="d-flex justify-content-end">
            <Button color={COLORS.GREEN} type="submit">
              {t('GLOBALS.DEFAULT_SUBMIT')}
            </Button>
          </div>
        </Form.Form>
      )}
    </div>
  );
};
