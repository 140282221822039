import cn from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form, SearchField } from '@Components/ui';
import { COLORS } from '@Constants/enums';

import styles from './SearchForm.module.scss';
import { SearchFormProps } from './SearchForm.props';

const SearchForm = ({ className, onSubmit, tagOptions }: SearchFormProps) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');

  const handleOnChange = (value: string) => {
    setSearchValue(value);
  };

  const handleSubmit = () => {
    onSubmit(searchValue);
  };

  return (
    <Form.Form className={cn(styles.SearchForm, className)} onSubmit={handleSubmit}>
      <SearchField
        tagOptions={tagOptions}
        name="search"
        placeholder={t('COMPONENTS.SEARCH_BLOCK.PLACEHOLDER')}
        onChange={handleOnChange}
      />

      <Button className={styles.SearchForm__SearchButton} color={COLORS.BLACK} type="submit">
        {t('COMPONENTS.SEARCH_BLOCK.SEARCH')}
      </Button>
    </Form.Form>
  );
};

export default SearchForm;
