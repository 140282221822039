import cn from 'classnames';
import Link from 'next/link';

import { COLORS } from '@Constants/enums';

import { Tag } from '../Tag/Tag';
import styles from './TagButton.module.scss';
import Props from './TagButton.props';

export const TagButton = ({
  children,
  color = COLORS.PRIMARY,
  href,
  onClick,
  activeColor = COLORS.PRIMARY,
  activeTextColor = COLORS.WHITE,
  isActive,
  className,
  id,
  ...props
}: Props) => {
  const tag = (
    <Tag
      borderColor={isActive ? activeColor : color}
      textColor={isActive ? activeTextColor : color}
      backgroundColor={isActive ? activeColor : undefined}
      className={cn(styles.TagButton, className)}>
      {children}
    </Tag>
  );

  return href ? (
    <Link href={href} {...props}>
      <a id={id} className={styles.TagButton__Link} href={href}>
        {tag}
      </a>
    </Link>
  ) : (
    <button className={styles.TagButton__Button} onClick={onClick} {...props}>
      {tag}
    </button>
  );
};
