import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterAccordion, TagSearchInput } from '@Components/ui';
import { FILTER_STATE } from '@Constants/enums';
import { formatString, hasFilterValues } from '@Utils/index';

import { Icon } from '../Icon/Icon';
import styles from './FilterMobile.module.scss';
import { FilterMobileProps } from './FilterMobile.props';

export const FilterMobile = ({
  title,
  filters,
  searchTags,
  onChange,
  resultCount,
  className,
  initialValues,
}: FilterMobileProps) => {
  const { t } = useTranslation();

  const [filter, setFilter] = useState({ branche: [] });
  const [filterState, setFilterState] = useState(FILTER_STATE.CLOSED);

  useEffect(() => {
    if (Object.values(filter || {}).length > 0 && onChange) {
      onChange({
        undefined,
        filter,
      });
    }
  }, [filter]);

  const handleSearchButton = () => {
    switch (filterState) {
      case FILTER_STATE.CLOSED:
        setFilterState(FILTER_STATE.SEARCH);
        break;
      case FILTER_STATE.FILTERS:
        setFilterState(FILTER_STATE.SEARCH);
        break;
      case FILTER_STATE.SEARCH:
        setFilterState(FILTER_STATE.CLOSED);
        break;
    }
  };

  const handleFilterButton = () => {
    switch (filterState) {
      case FILTER_STATE.CLOSED:
        setFilterState(FILTER_STATE.FILTERS);
        break;
      case FILTER_STATE.SEARCH:
        setFilterState(FILTER_STATE.FILTERS);
        break;
      case FILTER_STATE.FILTERS:
        setFilterState(FILTER_STATE.CLOSED);
        break;
    }
  };

  const handleRemoveFilters = (event: any) => {
    event.stopPropagation();
    setFilter({ branche: [] });
    onChange({
      undefined,
    });
    setFilterState(FILTER_STATE.CLOSED);
  };

  const hasFilters = hasFilterValues(filter);

  return (
    <div className={cn(styles.FilterMobile, className)}>
      <div className={styles.FilterMobile__Menu}>
        <div className={styles.FilterMobile__MenuContainer} onClick={handleSearchButton}>
          <Icon icon="search" size={10} />
          <div className={styles.FilterMobile__Search}>{t('COMPONENTS.FILTER_SIDEBAR.SEARCH')}</div>
          <div className={styles.FilterMobile__Results}>
            {t('COMPONENTS.FILTER_SIDEBAR.COUNT_RESULTS', { count: resultCount })}
          </div>
        </div>
        <div
          className={cn(
            styles.FilterMobile__MenuContainer,
            styles['FilterMobile__MenuContainer--LastItem'],
          )}
          onClick={handleFilterButton}>
          <Icon onClick={handleRemoveFilters} icon={hasFilters ? 'times' : 'plus'} size={10} />
          <div className={styles.FilterMobile__Filters}>
            {t('COMPONENTS.FILTER_SIDEBAR.FILTERS')}
          </div>
        </div>
      </div>

      <div className={styles.FilterMobile__Filter}>
        {filterState === FILTER_STATE.SEARCH && (
          <div className={styles.FilterMobile__FilterSearch}>
            <div className="container">
              <TagSearchInput
                branches={searchTags}
                value={filter?.branche?.[0]}
                onChange={(value: { branche: [] }) => setFilter(value)}
              />
            </div>
          </div>
        )}

        {filterState === FILTER_STATE.FILTERS && (
          <div className={styles.FilterMobile__FilterAccordion}>
            <FilterAccordion
              initialValues={initialValues}
              items={filters}
              useCheckboxes
              onChange={(values: any) => setFilter(values)}
            />
          </div>
        )}
      </div>
      {filterState === FILTER_STATE.CLOSED && (
        <div className="container mt-md">
          <h2
            className={styles.FilterMobile__Title}
            dangerouslySetInnerHTML={{ __html: formatString(title) }}
          />
        </div>
      )}
    </div>
  );
};
