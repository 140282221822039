/* eslint-disable quote-props */
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import React, { useState } from 'react';
import Select from 'react-select';

import styles from './SearchField.module.scss';
import { SearchFieldProps } from './SearchField.props';

export const SearchField = ({ className, placeholder, onChange, tagOptions }: SearchFieldProps) => {
  const [value, setValue] = useState({});

  const options = tagOptions.map((branch) => {
    return { value: branch.value, label: branch.value };
  });

  const style = {
    control: (base: any) => ({
      ...base,
      height: '100%',
      background: 'none',
      border: 'none',
      boxShadow: 'none',
      '&:hover': {
        border: 'none',
      },
    }),
  };
  return (
    <div className={cn(styles.SearchField, className)}>
      <button type="submit" className={cn(styles.SearchField__Button, className)}>
        <FontAwesomeIcon
          className={cn(styles.SearchField__Icon, className)}
          icon={faSearch}
          size="xs"
        />
      </button>

      <Select
        options={options}
        id="SearchField"
        className={cn(styles.SearchField__Input, className)}
        placeholder={placeholder}
        type="text"
        styles={style}
        value={value}
        onChange={(item: any) => {
          setValue(item);
          onChange && onChange(item.value);
        }}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
      />
    </div>
  );
};
