import { GraphQLClient as GQLClient } from 'graphql-request';

import { __CLIENT_SIDE__ } from '@Constants/environment';

const endpoint = process.env.NEXT_PUBLIC_PREPR_GRAPHQL_ENDPOINT;

export const GraphqlClient = new GQLClient(endpoint as string, {
  headers: {
    Authorization: String(
      __CLIENT_SIDE__ ? process.env.NEXT_PUBLIC_PREPR_ACCESS_TOKEN : process.env.PREPR_ACCESS_TOKEN,
    ),
  },
});

export const setPreviewMode = () => {
  GraphqlClient.setHeader(
    'Authorization',
    String(
      __CLIENT_SIDE__
        ? process.env.NEXT_PUBLIC_PREPR_ACCESS_TOKEN_PREVIEW
        : process.env.PREPR_ACCESS_TOKEN_PREVIEW,
    ),
  );
};

export const getRichText = (html?: string, className?: string) => {
  if (!html) {
    return '';
  }

  return <div dangerouslySetInnerHTML={{ __html: html }} className={`content ${className}`} />;
};

export const getFilters = (tags?: string[]) => {
  if (tags) {
    return `( where : { _tags_any : [ ${tags.map((id) => `"${id}"`).join(',')} ] } )`;
  }

  return '';
};

export const getMetaFields = () => {
  return `
    _id
    _created_on
    _changed_on
    _publish_on
    __typename
    _slug
    _read_time
  `;
};

export const getAssetField = (width?: number, height?: number) => {
  let assetSize = ``;

  if (width && height) {
    assetSize = `(width: ${width}, height: ${height})`;
  } else if (width) {
    assetSize = `(width: ${width})`;
  } else if (height) {
    assetSize = `(height: ${height})`;
  }

  return `
{
  _type
  name
  author
  description
  cdn_files {
    _id
    full: url ${assetSize}
  }
}
`;
};

export const PREPR_DRAG_AND_DROP = `{
	__typename

	... on Text {
		_id
		body
		format
	}

  ... on Button {
    _id
    label
    url
  }

  ... on Blockquote {
    _id
    blockquote
  }

	...on Video {
		_id
		youtube_id
	}

  ...on CTA {
		_id
		title
    button_label
    button_url
	}

	... on Carousel {
		_id
		images ${getAssetField()}
	}
}
`;

export const getTagField = () => {
  return `
  {
    _id
    body
  }
  `;
};

export const getTotalPages = (total: number, pageSize = 6) => {
  return Math.ceil(total / pageSize);
};
