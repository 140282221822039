import cn from 'classnames';
import Link from 'next/link';
import { useFormContext } from 'react-hook-form';

import { Spinner } from '@Components/ui';
import { COLORS } from '@Constants/enums';

import styles from './Button.module.scss';
import Props from './Button.props';

export const Button = ({
  children,
  className,
  color = COLORS.PRIMARY,
  href,
  onClick,
  type = 'button',
  isLoading,
  disabled,
  ...props
}: Props) => {
  const formContext = useFormContext();

  const isLoadingOrSubmitting =
    (type === 'submit' && formContext?.formState.isSubmitting) || isLoading;

  const classNames = cn(styles.Button, className, 'btn', styles[`Button--${color}`]);

  return href ? (
    <Link href={href} {...props}>
      <a className={classNames} {...props}>
        {children}
      </a>
    </Link>
  ) : (
    <button disabled={disabled} type={type} onClick={onClick} className={classNames} {...props}>
      <div
        className={cn(styles.Button__ChildContainer, {
          [styles['Button__ChildContainer--Loading']]: isLoadingOrSubmitting,
        })}>
        {children}
      </div>
      {isLoadingOrSubmitting && (
        <Spinner size={20} color="white" className={styles.Button__Loader} />
      )}
    </button>
  );
};
