import cn from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import { CircledIconButton, PromotionBanner, PromotionBannerProps } from '@Components/ui';
import { COLORS } from '@Constants/enums';
import { IPreprLink } from '@Constants/interfaces';

import styles from './Footer.module.scss';
import { FooterNavigationProps, FooterProps, FooterSubnavigationProps } from './Footer.props';

const FooterNavigation = ({ title, items }: FooterNavigationProps) => {
  return (
    <div className={styles.FooterNavigation}>
      <strong className={styles.FooterNavigation__Title}>{title}</strong>

      <div className={styles.FooterNavigation__Links}>
        {items?.map((item: IPreprLink) => (
          <Link key={item.link + item.label} href={String(item.link)}>
            <a className={styles.FooterNavigation__Link} href={String(item.link)}>
              {item.label}
            </a>
          </Link>
        ))}
      </div>
    </div>
  );
};

const FooterSubnavigation = ({
  items,
  facebook_url,
  instagram_url,
  linkedin_url,
}: FooterSubnavigationProps) => {
  return (
    <div className={styles.FooterSubnavigation}>
      <Link href="">
        <a href="" className={styles.FooterSubnavigation__Logo}>
          <Image src="/images/Logo.svg" layout="fill" objectFit="contain" />
        </a>
      </Link>

      <div className={styles.FooterSubnavigation__Links}>
        {items?.map((item: IPreprLink) => (
          <Link key={item.link + item.label} href={String(item.link)}>
            <a
              className={styles.FooterSubnavigation__Link}
              target="_blank"
              rel="noopener noreferrer"
              href={String('http://docs.google.com/gview?url=' + item.link)}>
              {item.label}
            </a>
          </Link>
        ))}
      </div>

      <div className={styles.FooterSubnavigation__Socials}>
        <CircledIconButton
          href={facebook_url}
          target="_blank"
          rel="noopener noreferrer"
          icon="facebook-f"
          iconType="brands"
          backgroundColor={COLORS.BLACK}
        />

        <CircledIconButton
          href={instagram_url}
          target="_blank"
          rel="noopener noreferrer"
          icon="instagram"
          iconType="brands"
          backgroundColor={COLORS.BLACK}
        />

        <CircledIconButton
          href={linkedin_url}
          target="_blank"
          rel="noopener noreferrer"
          icon="linkedin-in"
          iconType="brands"
          backgroundColor={COLORS.BLACK}
        />
      </div>
    </div>
  );
};

export const Footer = ({
  facebook_url,
  instagram_url,
  linkedin_url,
  column_one_title,
  column_one_links,
  column_two_title,
  column_two_links,
  column_three_title,
  column_three_links,
  sublinks,
  className,
}: FooterProps) => {
  const { t } = useTranslation();

  const bannerProps: PromotionBannerProps = {
    title: t('COMPONENTS.PROMOTION_BANNER.UNAUTHENTICATED.TITLE'),
    hasBackground: true,
  };

  return (
    <div className={cn(styles.Footer, className)}>
      <PromotionBanner
        {...bannerProps}
        newsletterLink="/nieuwsbrief"
        newsletterTitle={t('COMPONENTS.PROMOTION_BANNER.NEWSLETTER.TITLE')}
        className={styles.Footer__Banner}
      />
      <div className="container">
        <div className={cn(styles.Footer__Navigation, 'row')}>
          <div className="col-lg-3 col-md-6 col-sm-6 col-6">
            <FooterNavigation title={column_one_title} items={column_one_links} />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-6">
            <FooterNavigation title={column_two_title} items={column_two_links} />
          </div>
          <div className="col-lg-3 col-md-6 col-sm-6 col-6">
            <FooterNavigation title={column_three_title} items={column_three_links} />
          </div>
        </div>

        <div className={cn(styles.Footer__Subnavigation, 'row')}>
          <div className="col-12">
            <FooterSubnavigation
              items={sublinks}
              facebook_url={facebook_url || '#'}
              instagram_url={instagram_url || '#'}
              linkedin_url={linkedin_url || '#'}
            />
          </div>
        </div>
      </div>

      <div className={styles.Footer__Background} />
    </div>
  );
};
