import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Form } from '@Components/ui';
import { COLORS } from '@Constants/enums';
import { BackendClient } from '@Utils/BackendUtils';
import { getRichText } from '@Utils/PrismicUtils';
import Yup from '@Utils/YupUtils';

import { NewsletterFormData, NewsletterFormProps } from './NewsletterForm.props';

export const NewsletterForm = ({ title, body, className }: NewsletterFormProps) => {
  const { t } = useTranslation();
  const [hasSended, setHasSended] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    EMAIL: Yup.string().email().required(),
  });

  const onSubmit = async ({ EMAIL, FNAME, LNAME, TAG }: NewsletterFormData) => {
    setIsLoading(true);

    try {
      await BackendClient.post('/newsletter', {
        EMAIL,
        FNAME,
        LNAME,
        TAG,
      });

      setHasSended(true);
    } catch (e) {
      setError(t('FORMS.NEWSLETTER_FORM.ERROR'));
      setIsLoading(false);
    }
  };

  const tags = [
    { key: t('FORMS.NEWSLETTER_FORM.INHABITANT'), value: t('FORMS.NEWSLETTER_FORM.INHABITANT') },
    { key: t('FORMS.NEWSLETTER_FORM.COMPANY'), value: t('FORMS.NEWSLETTER_FORM.COMPANY') },
  ];

  return (
    <div className={className}>
      <h1 className="h2">{title}</h1>
      {getRichText(body, 'mb-md')}

      {hasSended && <p>{t('FORMS.NEWSLETTER_FORM.CONFIRMATION')}</p>}

      {!hasSended && (
        <Form.Form onSubmit={onSubmit} validationSchema={validationSchema}>
          <Form.Field label={t('FORMS.NEWSLETTER_FORM.FIRST_NAME_LABEL')}>
            <Form.TextInput name="FNAME" placeholder={t('GLOBALS.DEFAULT_PLACEHOLDER')} />
          </Form.Field>

          <Form.Field label={t('FORMS.NEWSLETTER_FORM.LAST_NAME_LABEL')}>
            <Form.TextInput name="LNAME" placeholder={t('GLOBALS.DEFAULT_PLACEHOLDER')} />
          </Form.Field>

          <Form.Field isRequired={true} label={t('FORMS.NEWSLETTER_FORM.EMAIL_LABEL')}>
            <Form.TextInput name="EMAIL" placeholder={t('GLOBALS.DEFAULT_PLACEHOLDER')} />
          </Form.Field>

          <Form.Field isRequired label={t('FORMS.NEWSLETTER_FORM.TAG_LABEL')}>
            <Form.SelectInput
              className="mb-md"
              name="TAG"
              options={tags.map(({ key, value }) => {
                return {
                  value: key,
                  label: value,
                };
              })}
            />
          </Form.Field>

          {error && <div className="Error mb-sm">{error}</div>}

          <Button isLoading={isLoading} type="submit" color={COLORS.GREEN}>
            {t('FORMS.NEWSLETTER_FORM.SUBMIT')}
          </Button>
        </Form.Form>
      )}
    </div>
  );
};
