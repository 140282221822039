import React from 'react';
import { useTranslation } from 'react-i18next';

import { CategoryCard } from '@Components/ui';
import { COLORS } from '@Constants/enums';

import { DealCategoryCardProps } from './DealCategoryCard.props';

export const DealCategoryCard = ({ className, deal }: DealCategoryCardProps) => {
  const { t } = useTranslation();

  return (
    <CategoryCard
      className={className}
      href={`${t('COMMON.ROUTES.DEALS')}/${deal._slug}`}
      thumbnailSrc={deal.image?.[0].cdn_files[0].full}
      categoryTitle={t('DOMAIN.DEAL.SINGLE')}
      categoryColor={COLORS.PINK}
      title={deal.title}
      tags={deal.branche}>
      {deal.company[0].title}
    </CategoryCard>
  );
};
