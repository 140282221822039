import cn from 'classnames';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, FilterMobile, FilterSidebar, OnFilterChangeParams } from '@Components/ui';
import { COLORS } from '@Constants/enums';
import { __CLIENT_SIDE__ } from '@Constants/environment';
import { IBaseModel, ListArgs } from '@Constants/interfaces';
import { hasFilterValues } from '@Utils/FilterUtil';
import { getRichText, getTotalPages } from '@Utils/PrismicUtils';

import styles from './ArchivePage.module.scss';
import { ArchivePageProps } from './ArchivePage.props';

export function ArchivePage<T extends IBaseModel, X = {}>({
  title,
  body,
  className,
  filters,
  searchTags,
  fetchNewDataHandler,
  items = [],
  renderItem,
  totalResults,
  note,
  loadMoreButtonColor = COLORS.WHITE,
  loadMoreButtonTextColor = COLORS.BLACK,
}: ArchivePageProps<T>) {
  const initialValues: any = {};

  if (__CLIENT_SIDE__) {
    const params = new URLSearchParams(window.location.search);
    const urlFilter = params.getAll('filter');
    const urlSearch = params.getAll('s');
    const urlPage = params.getAll('page');

    if (urlFilter && urlFilter[0]) {
      const parsedFilter = JSON.parse(urlFilter[0]);
      if (hasFilterValues(parsedFilter)) {
        initialValues.filter = parsedFilter;
      }
    }

    if (urlSearch && urlSearch[0]) {
      initialValues.s = urlSearch[0];
    }

    if (urlPage && urlPage[0]) {
      initialValues.page = urlPage[0];
    }
  }

  const [page, setPage] = useState<number>(initialValues.page || 1);
  const [isLoading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState<undefined | X>(initialValues.filter);
  const [search, setSearch] = useState(initialValues.search || '');
  const [totalPages, setTotalPages] = useState<number>(getTotalPages(totalResults));
  const [results, setResults] = useState(items || []);
  const router = useRouter();
  const { t } = useTranslation();

  const changeRoute = () => {
    const params: any = {};

    if (search) {
      params.s = search;
    }

    if (filter && hasFilterValues(filter)) {
      params.filter = JSON.stringify(filter);
    }

    if (page > 1) {
      params.page = page;
    }

    router.push(
      {
        query: params,
      },
      undefined,
      {
        shallow: true,
      },
    );
  };

  const fetchNewData = async (values: ListArgs) => {
    const { filter, search, page } = values;

    const args: ListArgs = {
      search: search || '',
      page,
      filter,
    };

    setIsLoading(true);
    const result = await fetchNewDataHandler(args);
    setIsLoading(false);

    setTotalPages(getTotalPages(result.total || 0));
    setResults(
      page === 1
        ? result.items || []
        : (items) => {
            return [...items, ...result.items];
          },
    );
  };

  useEffect(() => {
    changeRoute();
    fetchNewData({
      filter: filter ? Object.values(filter).flat(Infinity) : [],
      search,
      page,
    });
  }, [page, filter, search]);

  const handleFilterChange = (values: OnFilterChangeParams<X>) => {
    setFilter(values.filter);
    setSearch(values.search);
    setPage(1);
  };

  const handleNextPage = () => {
    setPage((page) => page + 1);
  };

  return (
    <div className={cn(styles.ArchivePage, className)}>
      <FilterMobile
        onChange={handleFilterChange}
        title={title}
        filters={filters}
        searchTags={searchTags}
        resultCount={results?.length}
        initialValues={initialValues.filter}
        initialSearch={initialValues.s}>
        {getRichText(body)}
      </FilterMobile>
      <section className="section overflow-visible">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <FilterSidebar
                onChange={handleFilterChange}
                title={title}
                filters={filters}
                searchTags={searchTags}
                initialValues={initialValues.filter}
                initialSearch={initialValues.s}>
                {getRichText(body)}
              </FilterSidebar>
            </div>
            <div className="col-lg-8">
              {!!note && (
                <small
                  className="d-block text-right mb-sm"
                  dangerouslySetInnerHTML={{ __html: note }}
                />
              )}

              <div className="row">
                {!results || results.length === 0 ? (
                  <h3 className={styles['ArchivePage__No-results']}>
                    {t('COMPONENTS.ARCHIVE_PAGE.NO_RESULTS')}
                  </h3>
                ) : (
                  results.map((item) => {
                    return (
                      <div key={item._id} className="col-lg-6 mb-md">
                        {renderItem(item)}
                      </div>
                    );
                  })
                )}
              </div>

              <div className="text-center mt-md">
                <Button
                  isLoading={isLoading}
                  color={loadMoreButtonColor}
                  disabled={!totalPages || page >= totalPages}
                  onClick={handleNextPage}>
                  <div className={`color-${loadMoreButtonTextColor}`}>
                    {t('COMPONENTS.ARCHIVE_PAGE.LOAD_MORE')}
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
