import cn from 'classnames';
import React from 'react';

import { COLORS } from '@Constants/enums';

import styles from './Tag.module.scss';
import Props from './Tag.props';

export const Tag = ({
  className,
  children,
  backgroundColor = COLORS.TRANSPARENT,
  textColor = COLORS.GRAY,
  borderColor = COLORS.LIGHT_GRAY,
}: Props) => {
  const classNames = cn([
    styles.Tag,
    `background-${backgroundColor}`,
    `color-${textColor}`,
    `border-${borderColor}`,
    className,
  ]);

  return <div className={classNames}>{children}</div>;
};
