import cn from 'classnames';

import { Footer, Header } from '@Components/layout';
import { COLORS } from '@Constants/enums';

import styles from './Page.module.scss';
import { PageProps } from './Page.props';

export const Page = ({ page, theme = COLORS.WHITE, children, className }: PageProps) => {
  return (
    <div className={cn(styles.Page, className)} data-theme={theme}>
      <Header {...page} />

      {children}

      <Footer {...page} />
    </div>
  );
};
