import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { FilterAccordion, TagSearchInput } from '@Components/ui';
import { formatString } from '@Utils/index';

import styles from './FilterSidebar.module.scss';
import { FilterSidebarProps } from './FilterSidebar.props';

export const FilterSidebar = ({
  title,
  filters,
  searchTags,
  onChange,
  children,
  className,
  initialValues,
}: FilterSidebarProps) => {
  const { t } = useTranslation();

  const [reRender, setReRender] = useState('');
  const [filter, setFilter] = useState({ branche: [] });

  useEffect(() => {
    if (Object.values(filter || {}).length > 0 && onChange) {
      onChange({
        undefined,
        filter,
      });
    }
  }, [filter]);

  return (
    <div className={cn(styles.FilterSidebar, className)}>
      <h2
        className={styles.FilterSidebar__Title}
        dangerouslySetInnerHTML={{ __html: formatString(title) }}
      />

      <div className={styles.FilterSidebar__Description}>{children}</div>

      <div className={styles.FilterSidebar__Filter}>
        <div className={styles.FilterSidebar__FilterSearch}>
          <strong className={styles.FilterSidebar__SearchTitle}>
            {t('COMPONENTS.FILTER_SIDEBAR.SEARCH_TITLE')}
          </strong>

          <TagSearchInput
            className={styles.FilterSidebar__Search}
            value={filter.branche}
            branches={searchTags}
            onChange={(value: any) => {
              setFilter(value);
              setReRender(Math.random().toString());
            }}
          />
        </div>

        <div className={styles.FilterSidebar__FilterAccordion}>
          <FilterAccordion
            key={reRender}
            initialValues={reRender.length === 0 ? initialValues : filter}
            items={filters}
            useCheckboxes
            onChange={(values: any) => {
              setFilter(values);
            }}
          />
        </div>
      </div>
    </div>
  );
};
