/* eslint-disable quote-props */
import cn from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import { Icon, SearchField, TagButton } from '@Components/ui';
import { COLORS } from '@Constants/enums';
import { IPreprLink } from '@Constants/interfaces';
import { getButtonID } from '@Utils/StringUtils';

import styles from './Header.module.scss';
import { HeaderDesktopProps, HeaderMobileProps, HeaderProps } from './Header.props';

const HeaderDesktop = ({ menu_links, quick_links, searchTags }: HeaderDesktopProps) => {
  const [isSearch, setIsSearch] = useState(false);
  const router = useRouter();
  const { t } = useTranslation();

  const options = searchTags.map((tag) => {
    return { value: tag.key, label: tag.value };
  });

  const handleSubmit = (value: string) => {
    if (value) {
      const filterParams = JSON.stringify({
        branche: [value],
      });

      router.push({
        pathname: t('COMMON.ROUTES.COMPANIES'),
        query: { filter: filterParams },
      });
    } else {
      router.push({
        pathname: t('COMMON.ROUTES.COMPANIES'),
      });
    }
  };

  const style = {
    control: (base: any) => ({
      ...base,
      height: '100%',
      background: 'none',
      border: 'none',
      boxShadow: 'none',
      '&:hover': {
        border: 'none',
      },
    }),
  };

  const toggleSearch = () => {
    setIsSearch((old) => !old);
  };

  return (
    <div className={styles.HeaderDesktop}>
      <div className={styles.HeaderDesktop__Row}>
        <Link href="/">
          <a href="/" className={styles.HeaderDesktop__Logo}>
            <Image src="/images/Logo.svg" layout="fill" objectFit="contain" />
          </a>
        </Link>

        <div className={styles.HeaderDesktop__Navigation}>
          {menu_links?.map((item: IPreprLink) => (
            <Link key={item.label} href={String(item.link)}>
              <a href={String(item.link)} className={styles.HeaderDesktop__NavigationItem}>
                {item.label}
              </a>
            </Link>
          ))}

          <div
            className={cn(styles.HeaderDesktop__Search, {
              [styles['HeaderDesktop__Search--Visible']]: isSearch,
            })}>
            {isSearch && (
              <div>
                <Select
                  options={options}
                  id="SearchField"
                  placeholder={t('COMPONENTS.HEADER.SEARCH')}
                  type="text"
                  styles={style}
                  onChange={(item: any) => {
                    handleSubmit(item.value);
                  }}
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                />
              </div>
            )}

            <Icon icon={isSearch ? 'times' : 'search'} type="regular" onClick={toggleSearch} />
          </div>
        </div>
      </div>

      <div className={styles.HeaderDesktop__Tags}>
        {quick_links?.map((tag: IPreprLink) => (
          <TagButton
            id={getButtonID(tag.link)}
            className={styles.HeaderDesktop__Tag}
            key={tag.label}
            color={COLORS.BLACK}
            activeColor={COLORS.BLACK}
            activeTextColor={COLORS.WHITE}
            isActive={tag.link === '/' + router.pathname.split('/')[1]}
            href={tag.link}>
            {tag.label}
          </TagButton>
        ))}
      </div>
    </div>
  );
};
const HeaderMobile = ({ menu_links, quick_links, searchTags }: HeaderMobileProps) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation();
  const router = useRouter();
  const SearchRef = useRef<HTMLDivElement>(null);

  const handleToggle = (withSearch = false) => {
    setVisible((oldVisible) => {
      const isVisible = !oldVisible;

      if (SearchRef.current) {
        const input = SearchRef.current.querySelectorAll(
          'input[type="text"',
        )[0] as HTMLInputElement;

        if (isVisible && withSearch) {
          input.focus();
        } else {
          input.value = '';
        }
      }

      return isVisible;
    });
  };
  const handleSubmit = (value: string) => {
    if (value) {
      const filterParams = JSON.stringify({
        branche: [value],
      });

      router.push({
        pathname: t('COMMON.ROUTES.COMPANIES'),
        query: { filter: filterParams },
      });
    } else {
      router.push({
        pathname: t('COMMON.ROUTES.COMPANIES'),
      });
    }
  };

  return (
    <div
      className={cn(styles.HeaderMobile, {
        [styles['HeaderMobile--Active']]: visible,
      })}>
      <div className={styles.HeaderMobile__Row}>
        <Link href="/">
          <a href="/" className={styles.HeaderMobile__Logo}>
            <Image src="/images/Logo.svg" layout="fill" objectFit="contain" />
          </a>
        </Link>

        <div className={styles.HeaderMobile__Navigation}>
          <div className={styles.HeaderMobile__NavigationItem}>
            <button onClick={() => handleToggle(true)} className={styles.HeaderMobile__Search}>
              <Icon icon="search" type="regular" size={15} />
            </button>
          </div>

          <div className={styles.HeaderMobile__NavigationItem}>
            <button
              onClick={() => handleToggle()}
              className={cn(styles.HeaderMobile__Toggler, {
                [styles['HeaderMobile__Toggler--Active']]: visible,
              })}>
              <span />
              <span />
              <span />
            </button>
          </div>
        </div>
      </div>

      <div className={styles.HeaderMobile__Dropdown}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className={styles.HeaderMobile__DropdownWrapper}>
                <div className={styles.HeaderMobile__DropdownTags}>
                  {quick_links?.map((tag: IPreprLink) => (
                    <TagButton
                      className={styles.HeaderMobile__DropdownTag}
                      key={tag.label}
                      color={COLORS.BLACK}
                      activeColor={COLORS.BLACK}
                      activeTextColor={COLORS.WHITE}
                      isActive={tag.link === '/' + router.pathname.split('/')[1]}
                      href={tag.link}>
                      {tag.label}
                    </TagButton>
                  ))}
                </div>

                <div className={styles.HeaderMobile__DropdownNavigation}>
                  {menu_links?.map((item: IPreprLink) => (
                    <Link key={item.label} href={String(item.link)}>
                      <a
                        href={String(item.link)}
                        className={styles.HeaderMobile__DropdownNavigationItem}>
                        {item.label}
                      </a>
                    </Link>
                  ))}
                </div>

                <div ref={SearchRef} className={styles.HeaderMobile__DropdownSearch}>
                  <SearchField
                    tagOptions={searchTags}
                    onChange={(item: any) => {
                      handleSubmit(item);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.HeaderMobile__DropdownBackground} />
      </div>
    </div>
  );
};
export const Header = ({
  menu_links,
  quick_links,
  className,
  branches,
  searchTags,
}: HeaderProps) => {
  const [isActive, setActive] = useState(false);

  const handleScroll = () => {
    setActive(window.pageYOffset > 8);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const classNames = cn(styles.Header, className, {
    [styles['Header--Active']]: isActive,
  });

  return (
    <div className={classNames}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className={styles.Header__Desktop}>
              <HeaderDesktop
                menu_links={menu_links}
                quick_links={quick_links}
                branches={branches}
                searchTags={searchTags}
              />
            </div>

            <div className={styles.Header__Mobile}>
              <HeaderMobile
                menu_links={menu_links}
                quick_links={quick_links}
                branches={branches}
                searchTags={searchTags}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
