import cn from 'classnames';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import { SearchForm } from '@Components/forms';
import { TagButton } from '@Components/ui';
import { COLORS } from '@Constants/enums';
import { formatString } from '@Utils/StringUtils';

import styles from './SearchBlock.module.scss';
import { SearchBlockProps } from './SearchBlock.props';

export const SearchBlock = ({ className, searchTags, branches }: SearchBlockProps) => {
  const router = useRouter();
  const { t } = useTranslation();

  const handleSubmit = (value: string) => {
    if (value) {
      const filterParams = JSON.stringify({
        branche: [value],
      });

      router.push({
        pathname: t('COMMON.ROUTES.COMPANIES'),
        query: { filter: filterParams },
      });
    } else {
      router.push({
        pathname: t('COMMON.ROUTES.COMPANIES'),
      });
    }
  };

  return (
    <div className={cn(styles.SearchBlock, className)}>
      <h2
        className={styles.SearchBlock__Title}
        dangerouslySetInnerHTML={{
          __html: formatString(t('COMPONENTS.SEARCH_BLOCK.TITLE')),
        }}
      />

      <SearchForm
        className={styles.SearchBlock__Form}
        onSubmit={handleSubmit}
        tagOptions={searchTags}
      />

      <div className={styles.SearchBlock__TagCloud}>
        <h3 className={styles.SearchBlock__TagCloudTitle}>
          {t('COMPONENTS.SEARCH_BLOCK.SUBTITLE')}
        </h3>
        <div className={styles.SearchBlock__TagCloudItems}>
          {branches.map((tag) => {
            const filterParams = JSON.stringify({
              branche: [tag.key],
            });

            return (
              <TagButton
                key={tag.value}
                href={`${t('COMMON.ROUTES.COMPANIES')}?filter=${encodeURIComponent(filterParams)}`}
                isActive={true}
                activeColor={COLORS.LIGHTER_GRAY}
                activeTextColor={COLORS.BLACK}>
                {tag.value}
              </TagButton>
            );
          })}
        </div>
      </div>
    </div>
  );
};
